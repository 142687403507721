import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {NzGridModule} from 'ng-zorro-antd/grid';


import {UserRoutingModule} from './user-routing.module';
import {BackgroundUserComponent} from './background-user/background-user.component';
import {AppletUserComponent} from './applet-user/applet-user.component';


@NgModule({
    declarations: [BackgroundUserComponent, AppletUserComponent],
    imports: [
        CommonModule,
        NzLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        NzGridModule,
        UserRoutingModule
    ]
})
export class UserModule {
}
