import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {Config} from '../../config';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-land',
  templateUrl: './land.component.html',
  styleUrls: ['./land.component.less']
})
export class LandComponent implements OnInit {

  constructor(
    private router: Router,
    public api: ApiService,
    private cookie: CookieService,
  ) { }

  ngOnInit() {
    this.getAuth();
  }

  getAuth() {
    if (!this.cookie.get('token')) {
      this.router.navigate(['/login']).then();
      return false;
    }
    this.api.post(Config.Host + Config.AdminSecret, {token: this.cookie.get('token')}).subscribe((result: any) => {
      if (result.state !== 'success') {
        this.router.navigate(['/login']).then();
        this.cookie.delete('token');
        return false;
      }
      return true;
    });
  }
}
