import {Component, OnInit} from '@angular/core';
import {Router, ActivationEnd, NavigationEnd, ActivatedRouteSnapshot, ActivatedRoute} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {Config} from '../../config';
import {NzMessageService} from 'ng-zorro-antd';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.less']
})
export class IndexComponent implements OnInit {

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private router: Router
  ) {
  }

  // 定义变量
  data: any = {
    MinaUsers: 0,
    BackstageUsers: 0,
    PassProducts: 0,
    LineProducts: 0
  };

  ngOnInit() {
    // this.getMinaUsersCount();
    // this.getBackstageUsersCount();
    // this.getPassProductsCount();
    // this.getLineProductsCount();
  }

  // 微信用户数
  // getMinaUsersCount() {
  //   this.api.get(Config.Host + Config.MinaUsersCount).subscribe((result: any) => {
  //     this.data.MinaUsers = result;
  //   });
  // }

  // 后台用户数
  // getBackstageUsersCount() {
  //   this.api.get(Config.Host + Config.AdminCount).subscribe((result: any) => {
  //     this.data.BackstageUsers = result;
  //   });
  // }

  // 通过审核产品数
  // getPassProductsCount() {
  //   this.api.get(Config.Host + Config.PassProductCount).subscribe((result: any) => {
  //     this.data.PassProducts = result;
  //   });
  // }

  // 线上产品数
  // getLineProductsCount() {
  //   this.api.get(Config.Host + Config.LineProductCount).subscribe((result: any) => {
  //     this.data.LineProducts = result;
  //   });
  // }
}
