import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {NzGridModule} from 'ng-zorro-antd/grid';

import {UnitRoutingModule} from './unit-routing.module';
import {QuantityUnitComponent} from './quantity-unit/quantity-unit.component';
import {CurrencyUnitComponent} from './currency-unit/currency-unit.component';


@NgModule({
    declarations: [QuantityUnitComponent, CurrencyUnitComponent],
    imports: [
        CommonModule,
        NzLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        NzGridModule,
        UnitRoutingModule
    ]
})
export class UnitModule {
}
