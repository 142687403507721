import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from '../../services/auth.service';
import {ApiService} from '../../services/api.service';
import {Config} from '../../config';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.less']
})
export class BaseComponent implements OnInit {

  constructor(
    public router: Router,
    public api: ApiService,
    private auth: AuthService,
    private cookie: CookieService
  ) {
  }

  isCollapsed = false;
  username: any; // 用户名
  authNum: 4;  // 权限

  openMap: { [name: string]: boolean } = {
    sub1: false,
    sub2: false
  };

  ngOnInit() {
    this.checkAuth();
  }

  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }

  // 退出登录
  logout() {
    this.cookie.delete('token');
    this.cookie.delete('username');
    this.router.navigate(['/login']).then();
  }

  // 验证token
  checkAuth() {
    this.api.post(Config.Host + Config.AdminSecret, {token: this.cookie.get('token')}).subscribe((result: any) => {
      if (result.state !== 'success') {
        this.router.navigate(['/login']).then();
        this.cookie.delete('token');
        return false;
      }
      this.username = result.data.nickname;
      this.cookie.set('username', this.username);
      this.authNum = result.data.scope;
    });
  }

}
