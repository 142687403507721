export const Config = {
  // Host: 'https://93nongxun.com', // 服务器
  Host: 'http://49.233.213.67:5002',
  AdminToken: '/pc/api/v1/users/token', // 用户登录
  AdminSecret: '/pc/api/v1/users/secret', // 用户登录信息
  AdminRegister: '/pc/api/v1/users/register', // 用户注册
  AdminCount: '/pc/api/v1/users/count', // 用户数量
  AdminSearch: '/pc/api/v1/users/some', // 用户模糊查询
  AdminDetail: '/pc/api/v1/users/detail', // 用户详情
  AdminCreateOne: '/pc/api/v1/users/create/one', // 用户创建
  AdminCreateMany: '/pc/api/v1/users/create/many', // 用户创建
  AdminUpdate: '/pc/api/v1/users/update', // 用户修改
  AdminDelete: '/pc/api/v1/users/delete', // 用户删除
  CurrencyUnitFuzzyQuery: '/pc/api/v1/price_unit/fuzzy/query', // 货币单位模糊查询
  CurrencyUnitAll: '/pc/api/v1/price_unit/all', // 货币单位所有查询
  CurrencyUnitDetail: '/pc/api/v1/price_unit/detail', // 货币单位详情查询
  CurrencyUnitCreateOne: '/pc/api/v1/price_unit/create/one', // 货币单位单条创建
  CurrencyUnitCreateMany: '/pc/api/v1/price_unit/create/many', // 货币单位多条创建
  CurrencyUnitDeleteOne: '/pc/api/v1/price_unit/delete/one', // 货币单位删除单条
  CurrencyUnitUpdateOne: '/pc/api/v1/price_unit/update/one', // 货币单位更新单条
  QuantityUnitFuzzyQuery: '/pc/api/v1/quantity_unit/fuzzy/query', // 计量单位模糊查询
  QuantityUnitAll: '/pc/api/v1/quantity_unit/all', // 计量单位所有查询
  QuantityUnitDetail: '/pc/api/v1/quantity_unit/detail', // 计量单位详情查询
  QuantityUnitCreateOne: '/pc/api/v1/quantity_unit/create/one', // 计量单位单条创建
  QuantityUnitCreateMany: '/pc/api/v1/quantity_unit/create/many', // 计量单位多条创建
  QuantityUnitDeleteOne: '/pc/api/v1/quantity_unit/delete/one', // 计量单位删除单条
  QuantityUnitUpdateOne: '/pc/api/v1/quantity_unit/update/one', // 计量单位更新单条
};
