import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base/base.component';
import { LandComponent } from './land/land.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [BaseComponent, LandComponent],
  imports: [
    CommonModule,
    RouterModule,
    NzLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule
  ],
  exports: [BaseComponent, LandComponent]
})
export class LayoutModule { }
