import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CurrencyUnitComponent} from './currency-unit/currency-unit.component';
import {QuantityUnitComponent} from './quantity-unit/quantity-unit.component';

const routes: Routes = [
    {
        path: 'CurrencyUnit',
        component: CurrencyUnitComponent
    },
    {
        path: 'QuantityUnit',
        component: QuantityUnitComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UnitRoutingModule {
}
