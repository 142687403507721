import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {Md5} from 'ts-md5/dist/md5';
import {Config} from '../../config';
import {NzMessageService} from 'ng-zorro-antd/message';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  constructor(
    public router: Router,
    public api: ApiService,
    private message: NzMessageService,
    private cookie: CookieService,
    private fb: FormBuilder,
  ) {
  }

  validateForm: FormGroup;

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      account: [null, [Validators.required]],
      password: [null, [Validators.required]],
      type: [101, [Validators.required]]
    });
  }

  // 登录提交
  submit() {
    const validateFormList = Object.keys(this.validateForm.controls);
    validateFormList.forEach((item: any) => {
      this.validateForm.controls[item].markAsDirty();
      this.validateForm.controls[item].updateValueAndValidity();
    });
    this.validateForm.value.password = Md5.hashStr(this.validateForm.value.password);
    this.api.post(Config.Host + Config.AdminToken, this.validateForm.value).subscribe((result: any) => {
      if (JSON.stringify(result) !== '{}') {
        this.api.post(Config.Host + Config.AdminSecret, {token: result.token}).subscribe((res: any) => {
          if (res.state === 'success') {
            this.cookie.set('token', result.token);
            this.router.navigate(['/index']).then();
          } else {
            this.message.error(res.message);
          }
        });
      }
    });
  }

}
