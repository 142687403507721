import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BaseComponent} from './layout/base/base.component';
import {LandComponent} from './layout/land/land.component';
import {LoginComponent} from './login/login/login.component';
import {LoginService} from './services/login.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'index',
        loadChildren: './index/index.module#IndexModule'
      },
      {
        path: 'Unit',
        loadChildren: './basic-data/unit/unit.module#UnitModule'
      },
      {
        path: 'Products',
        loadChildren: './basic-data/products/products.module#ProductsModule'
      },
        {
        path: 'User',
        loadChildren: './basic-data/user/user.module#UserModule'
      }
    ], canActivate: [LoginService]
  },
  {
    path: 'login',
    component: LandComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
