import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CanActivate} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements CanActivate {

  constructor(
    public router: Router,
    private cookie: CookieService,
  ) {
  }

  // 路由守护，判断用户是否登录
  canActivate() {
    if (this.cookie.get('token')) {
      return true;
    } else {
      this.router.navigate(['/login']).then();
      return false;
    }
  }
}
