import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {Config} from '../config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public router: Router,
    private api: ApiService,
    private cookie: CookieService
  ) {
  }

  // 判断权限是否合法或过期(路由)
  getAuth(data) {
    this.api.post(Config.Host + Config.AdminSecret, {token: data}).subscribe((result: any) => {
      if (result.state !== 'success') {
        this.router.navigate(['/login']).then();
        this.cookie.delete('token');
        return false;
      }
      this.router.navigate(['/index']).then();
      return true;
    });
  }
}
