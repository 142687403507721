import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(
    public http: HttpClient,
    private cookies: CookieService
  ) {
  }

  // 定义请求头，注意开启后端API接口支持跨域
  httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Basic ' + this.cookies.get('token')
    })
  };

  // get请求
  get(url: string): Observable<any> {
    return this.http.get(url, this.httpOptions);
  }

  // post请求
  post(url: string, data: any): Observable<any> {
    return this.http.post(url, data, this.httpOptions);
  }

  // put请求
  put(url: string, data: any): Observable<any> {
    return this.http.put(url, data, this.httpOptions);
  }

  // delete请求
  delete(url: string, data: any): Observable<any> {
    this.httpOptions.body = data;
    return this.http.delete(url, this.httpOptions);
  }
}
