import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agriculture-product',
  templateUrl: './agriculture-product.component.html',
  styleUrls: ['./agriculture-product.component.less']
})
export class AgricultureProductComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
