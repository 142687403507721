import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BackgroundUserComponent} from './background-user/background-user.component';
import {AppletUserComponent} from './applet-user/applet-user.component';

const routes: Routes = [
    {
        path: 'BackgroundUser',
        component: BackgroundUserComponent
    },
    {
        path: 'AppletUser',
        component: AppletUserComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
