import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Config} from '../../../config';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd';
import * as Ex from 'xlsx';

@Component({
    selector: 'app-currency-unit',
    templateUrl: './currency-unit.component.html',
    styleUrls: ['./currency-unit.component.less']
})
export class CurrencyUnitComponent implements OnInit {

    // @ts-ignore
    @ViewChild('printTable') printTable: ElementRef;

    constructor(
        public api: ApiService,
        private message: NzMessageService,
        private fb: FormBuilder
    ) {
    }

    isView = false;
    keyWord = '';
    Items: any;
    validateForm: FormGroup;
    isEdit = false;
    visible = false;

    ngOnInit(): void {
        this.getData();
        this.validateForm = this.fb.group({
            id: [null],
            name: [null, [Validators.required]],
        });
    }

    // 渲染数据u
    getData() {
        this.Items = [];
        this.api.get(Config.Host + Config.CurrencyUnitFuzzyQuery + '?key=' + this.keyWord).subscribe((result: any) => {
            this.isView = result.length > 0 ? true : false;
            this.Items = result;
        });
    }

    // 添加抽屉框
    add(): void {
        this.isEdit = false;
        this.visible = true;
        this.validateForm.reset();
    }

    // 编辑抽屉框
    edit(data: any): void {
        this.visible = true;
        this.isEdit = true;
        this.validateForm.reset(data);
    }

    // 关闭抽屉框
    close(): void {
        this.visible = false;
    }

    submitForm() {
        const validateFormList = Object.keys(this.validateForm.controls);
        validateFormList.forEach((item: any) => {
            this.validateForm.controls[item].markAsDirty();
            this.validateForm.controls[item].updateValueAndValidity();
        });
        if (this.isEdit) {
            // 修改数据的接口
            this.api.put(Config.Host + Config.CurrencyUnitUpdateOne, this.validateForm.value).subscribe((result: any) => {
                if (result.state === 'success') {
                    this.message.success(result.message);
                    this.getData();
                    this.close();
                } else {
                    this.message.error('提交失败，请确认！');
                    this.close();
                }
            });
        } else {
            // 提交数据的接口
            this.api.post(Config.Host + Config.CurrencyUnitCreateOne, this.validateForm.value).subscribe((result: any) => {
                if (result.state === 'success') {
                    this.message.success(result.message);
                    this.getData();
                    this.close();
                } else {
                    this.message.error('提交失败，请确认！');
                    this.close();
                }
            });
        }
    }

    // 删除数据
    delete(data: any) {
        this.api.delete(Config.Host + Config.CurrencyUnitDeleteOne + '?id=' + data.id, {}).subscribe((result: any) => {
            if (result.state === 'success') {
                this.message.success(result.message);
                this.getData();
                return true;
            }
            this.message.error('删除失败！');
        });
    }

    // 取消删除
    cancel(): void {
    }

    // 导出Excel
    exportTable() {
        const wb: Ex.WorkBook = Ex.utils.book_new();
        const worksheet: Ex.WorkSheet = Ex.utils.table_to_sheet(this.printTable.nativeElement, {raw: true});
        Ex.utils.book_append_sheet(wb, worksheet, '货币单位');
        Ex.writeFile(wb, '货币单位.xls');
    }
}


