import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {NzGridModule} from 'ng-zorro-antd/grid';

import {ProductsRoutingModule} from './products-routing.module';
import {AgricultureProductComponent} from './agriculture-product/agriculture-product.component';


@NgModule({
    declarations: [AgricultureProductComponent],
    imports: [
        CommonModule,
        NzLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        NzGridModule,
        ProductsRoutingModule
    ]
})
export class ProductsModule {
}
